import { Dialog, Transition } from '@headlessui/react';
import { Fragment } from 'react';
import IconX from './icons/x';
import { config } from './config';
import cx from 'classnames';
import { useRPCApiEndpointChanger } from './hooks/useRPC';

type Props = {
    isOpen: boolean;
    requestClose: () => void;
};

export default function ApiEndpointChooserDialog(props: Props) {
    const { isOpen } = props;

    const [endpoint, setEndpoint] = useRPCApiEndpointChanger();

    const options = [
        config.apiUrl,
        'https://wax.greymass.com',
        'https://waxapi.ledgerwise.io',
        'https://wax.pink.gg',
        'https://wax.eu.eosamsterdam.net',
        'https://api-wax.eosarabia.net',
        'https://api-wax.eosauthority.com',
        'https://api.hivebp.io',
        'https://apiwax.3dkrender.com',
        'https://wax-public.neftyblocks.com',
        'https://wax.cryptolions.io',
        'https://api.wax.eosdetroit.io',
        'https://api.wax.alohaeos.com',
        'https://wax.blokcrafters.io',
        'https://api-wax-mainnet.wecan.dev',
        'https://wax.eosphere.io',
        'https://wax.api.eosnation.io',
    ];

    return (
        <Transition appear show={isOpen} as={Fragment}>
            <Dialog
                as="div"
                className="fixed inset-0 z-10 overflow-y-auto"
                onClose={props.requestClose}
            >
                <div className="min-h-screen px-4 text-center">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Dialog.Overlay className="fixed inset-0 bg-dark bg-opacity-50" />
                    </Transition.Child>

                    {/* This element is to trick the browser into centering the modal contents. */}
                    <span
                        className="inline-block h-screen align-middle"
                        aria-hidden="true"
                    >
                        &#8203;
                    </span>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 scale-95"
                        enterTo="opacity-100 scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 scale-100"
                        leaveTo="opacity-0 scale-95"
                    >
                        <div className="inline-block w-full max-w-md p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-dark border-neon-yellow">
                            <Dialog.Title
                                as="h3"
                                className="text-lg font-medium leading-6 text-gray-100"
                            >
                                ⛓️️️ Choose WAX RPC Endpoint
                            </Dialog.Title>
                            <div className="absolute top-0 right-0 p-6">
                                <button onClick={props.requestClose}>
                                    <IconX className="text-white" />
                                </button>
                            </div>
                            <div className="mt-2 space-y-4 flex flex-col h-64 overflow-y-auto">
                                {options.map((e) => (
                                    <button
                                        key={e}
                                        className={cx(
                                            'rounded border-2 text-white text-left px-4 py-2',
                                            {
                                                'border-green-400':
                                                    e === endpoint,
                                                'border-gray-600':
                                                    e !== endpoint,
                                            },
                                        )}
                                        onClick={() => setEndpoint(e)}
                                    >
                                        <code>{e}</code>
                                    </button>
                                ))}
                            </div>

                            <div className="mt-4">
                                <button
                                    type="button"
                                    className="inline-flex justify-center px-4 py-2 text-sm font-medium text-primary bg-gray-900 border border-transparent rounded-md hover:bg-gray-800 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-yellow-500"
                                    onClick={props.requestClose}
                                >
                                    Confirm
                                </button>
                            </div>
                        </div>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition>
    );
}
