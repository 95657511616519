import ClientOAuth2 from 'client-oauth2';

export type AppConfig = {
    name: string;
    nodeUrl: string;
    apiUrl: string;
    atomicExplorerEndpoint: string;
    chainId: string;
    appName: string;
    contractAccount: string;
    collectionName: string;
    schemaName: string;
    atomichubBaseUrl: string;

    shop: {
        contractAccount: string;
    };

    token: {
        contractAccount: string;
    };

    burning: {
        contractAccount: string;
    };

    connect: {
        clientId: string;
        accessTokenUri: string;
        authorizationUri: string;
        mintsEndpoint: string;
    };
};

const urlSearchParams = new URLSearchParams(window.location.search);
let apiUrl = urlSearchParams.get('rpc');
if (apiUrl && !apiUrl.startsWith('https://')) {
    apiUrl = `https://${apiUrl}`;
}

const mainConfig: AppConfig = {
    name: 'main',
    nodeUrl: 'https://wax.greymass.com',
    apiUrl: apiUrl || 'https://api.waxsweden.org',
    atomicExplorerEndpoint: 'https://wax.api.atomicassets.io',
    chainId: '1064487b3cd1a897ce03ae5b6a865651747e2e152090f99c1d19d44e01aea5a4',
    appName: 'monkeymatching',
    contractAccount: 'monkeysmatch',
    collectionName: 'crptomonkeys',
    schemaName: 'crptomonkeys',
    atomichubBaseUrl: 'https://wax.atomichub.io',
    shop: {
        contractAccount: 'monkeymarket',
    },
    token: {
        contractAccount: 'monkeystoken',
    },

    burning: {
        contractAccount: 'monkeyburner',
    },

    connect: {
        clientId: 'Kqg4yZmTQsIGkJuNsZPG34X4KN3VdE9mXOVpTknE',
        authorizationUri: 'https://connect.cryptomonkeys.cc/o/authorize',
        accessTokenUri: 'https://connect.cryptomonkeys.cc/o/token/',
        mintsEndpoint:
            'https://connect.cryptomonkeys.cc/accounts/api/v1/findmatch/',
    },
};

const testConfig: AppConfig = {
    name: 'test',
    nodeUrl: 'https://waxtestnet.greymass.com',
    apiUrl: apiUrl || 'https://testnet.waxsweden.org',
    atomicExplorerEndpoint: 'https://test.wax.api.atomicassets.io',
    chainId: 'f16b1833c747c43682f4386fca9cbb327929334a762755ebec17f6f23c9b8a12',
    appName: 'monkeymatching-test',
    contractAccount: 'bvyzsshbnkjl',
    collectionName: 'bvyzsshbnkjl',
    schemaName: 'test',
    atomichubBaseUrl: 'https://wax-test.atomichub.io',
    shop: {
        contractAccount: 'xummfxbitlwa',
    },
    token: {
        contractAccount: 'wfqzrtsviwdq',
    },
    burning: {
        contractAccount: 'a4eff4211c1f',
    },
    connect: {
        clientId: '',
        authorizationUri: '',
        accessTokenUri: '',
        mintsEndpoint: '',
    },
};

const envs = {
    main: mainConfig,
    test: testConfig,
} as const;

const defaultEnv = process.env.NODE_ENV === 'production' ? 'main' : 'test';
const env = (localStorage.getItem('env') ?? defaultEnv) as keyof typeof envs;

export const config = envs[env];

export const connectOAuthClient = new ClientOAuth2({
    clientId: config.connect.clientId,
    accessTokenUri: config.connect.accessTokenUri,
    authorizationUri: config.connect.authorizationUri,
    redirectUri: window.location.origin + '/match/noexit/callback',
    scopes: ['match'],
});
