import { selector } from 'recoil';
import { eosRPCQuery } from './hooks/useRPC';
import { config } from './config';
import { ShopABI } from './abi';
import { explorerApiQuery } from './hooks/useExplorerApi';
import { authQueries } from './hooks/useAuth';

const storeEntriesQuery = selector({
    key: 'shop/storeEntriesQuery',
    get: async ({ get }) => {
        const rpc = get(eosRPCQuery);

        let hasMore = true;
        let lower_bound = '';

        const template_ids: Record<string, ShopABI._store_entity> = {};

        let i = 0;
        while (hasMore && i < 1000) {
            i++;
            const res = await rpc.get_table_rows({
                json: true,
                code: config.shop.contractAccount,
                scope: config.shop.contractAccount,
                table: 'store',
                lower_bound,
                limit: 100,
            });
            lower_bound = res.next_key;
            hasMore = res.more;

            const rows = res.rows as ShopABI._store_entity[];

            rows.forEach((row) => {
                template_ids[row.template_id] = row;
            });
        }
        return template_ids;
    },
});

const marketAccountStatsQuery = selector({
    key: 'shop/marketAccountStatsQuery',
    get: async ({ get }) => {
        const ah = get(explorerApiQuery);
        return await ah.getAccountCollection(
            config.shop.contractAccount,
            config.collectionName,
        );
    },
});

export const availableStoreEntriesQuery = selector({
    key: 'shop/availableStoreEntriesQuery',
    get: async ({ get }) => {
        const entries = get(storeEntriesQuery);
        const stats = get(marketAccountStatsQuery);
        const ah = get(explorerApiQuery);

        const availableEntries = Object.entries(entries).filter(
            ([template_id]) => {
                const stock = parseInt(
                    stats.templates.find((t) => t.template_id === template_id)
                        ?.assets ?? '0',
                    10,
                );
                return stock > 0;
            },
        );

        if (availableEntries.length === 0) {
            return {};
        }

        const templates = await ah.getTemplates({
            ids: availableEntries.map((e) => e[0]).join(','),
        });

        return Object.fromEntries(
            availableEntries.map(([template_id, store_entity]) => [
                template_id,
                {
                    store_entity,
                    template: templates.find(
                        (t) => t.template_id === template_id,
                    ),
                },
            ]),
        );
    },
});

export const shopTimeoutSecondsQuery = selector({
    key: 'shop/shopTimeoutSecondsQuery',
    get: async ({ get }) => {
        const rpc = get(eosRPCQuery);

        const res = await rpc.get_table_rows({
            json: true,
            code: config.shop.contractAccount,
            scope: config.shop.contractAccount,
            table: 'config',
            limit: 1,
        });
        const rows = res.rows as ShopABI._config_entity[];
        return rows[0].params.timeout_seconds;
    },
});

export const shopTimeoutUntilQuery = selector({
    key: 'shop/shopTimeoutQuery',
    get: async ({ get }): Promise<Date | null> => {
        const user = get(authQueries.activeUserName);
        if (!user) {
            return null;
        }

        const rpc = get(eosRPCQuery);
        const res = await rpc.get_table_rows({
            json: true,
            code: config.shop.contractAccount,
            scope: config.shop.contractAccount,
            table: 'users',
            lower_bound: user,
            upper_bound: user,
            limit: 1,
        });
        const rows = res.rows as ShopABI._users_entity[];
        if (rows.length === 0) {
            return null;
        }
        const lastBuy = new Date(rows[0].last_buy + 'Z');
        console.log(lastBuy);

        const until = new Date(
            lastBuy.getTime() + get(shopTimeoutSecondsQuery) * 1000,
        );
        console.log(until);
        if (until < new Date()) {
            return null;
        }
        return until;
    },
});
