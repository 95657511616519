export default function IconX(props: { className?: string }) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            className={('h-5 w-5 ' + props.className).trim()}
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
        >
            <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M6 18L18 6M6 6l12 12"
            />
        </svg>
    );
}
