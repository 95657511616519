import { Game } from './hooks/useCurrentGame';
import cx from 'classnames';
import { toast } from 'react-toastify';
import { useUserLevel } from './hooks/useUserLevel';
import { useRewardForCompletions } from './hooks/useRewards';
import { config } from './config';
import { useConfig } from './hooks/useConfig';
import SolidClipboardCopy from './icons/solid-clipboard-copy';

type Props = {
    game: Game;
    targetMints: { [key: string]: boolean | undefined };
};

export default function CurrentGameDisplay(props: Props) {
    const lvl = useUserLevel();
    const reward = useRewardForCompletions(lvl + 1);
    const cfg = useConfig();

    const size = props.game.to_collect.length;
    const required = size - Math.ceil(Math.log10(size));

    const sorted = [...props.game.to_collect].sort((a, b) => a - b);

    const copyToClipboard = async () => {
        const needed = Object.entries(props.targetMints)
            .filter((e) => !!e[1])
            .map((e) => e[0])
            .sort((a, b) => parseInt(a, 10) - parseInt(b, 10));

        await navigator.clipboard.writeText(
            `${needed.join(',')} (+/- ${cfg.mint_offset})`,
        );
        toast.success('Required mints copied to clipboard');
    };

    return (
        <div className="overflow-y-auto h-full flex flex-col space-x-2 bg-dark bg-opacity-25 border-neon-purple px-4 py-6">
            <div className="flex flex-wrap items-center justify-center">
                {sorted.map((i) => (
                    <a
                        key={i}
                        className={cx(
                            'm-2 w-24 h-24 relative rounded flex items-center justify-center bg-dark bg-opacity-50 font-bold text-2xl transform duration-150 ease-in-out hover:scale-105',
                            {
                                'text-cyan': props.targetMints[i],
                                'border-neon-cyan text-neon-cyan':
                                    !props.targetMints[i],
                            },
                        )}
                        href={`${
                            config.atomichubBaseUrl
                        }/market?collection_name=${
                            config.collectionName
                        }&schema_name=${config.schemaName}&max_template_mint=${
                            i + cfg.mint_offset
                        }&min_template_mint=${i - cfg.mint_offset}`}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        # {i}
                        <span className="absolute text-xs bottom-0 pb-1 font-normal">
                            {i - cfg.mint_offset}-{i + cfg.mint_offset}
                        </span>
                    </a>
                ))}
                <button
                    className="m-2 w-24 h-24 relative rounded flex items-center justify-center bg-dark bg-opacity-50 hover:bg-opacity-25"
                    onClick={copyToClipboard}
                >
                    <SolidClipboardCopy className="text-white" />
                    <span className="absolute text-xs bottom-0 pb-1 text-white">
                        Copy mints
                    </span>
                </button>
            </div>
            <div className="text-center text-white pt-4">
                <span className="bg-dark px-4 py-1">
                    Your reward for collecting at least {required} of these
                    mints: <span className="font-bold">{reward}</span>
                </span>
            </div>
        </div>
    );
}
