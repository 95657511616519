import {
    createContext,
    PropsWithChildren,
    useContext,
    useEffect,
    useState,
} from 'react';
// @ts-ignore
import { withUAL, UALProvider } from 'ual-reactjs-renderer';
import { Anchor } from 'ual-anchor';
import { config } from '../config';
import { AnyAction, PermissionLevel } from '@greymass/eosio';
import { toast } from 'react-toastify';
import { Wax } from '@eosdacio/ual-wax';
import { Wombat } from 'ual-wombat';
import { atom, selector, useSetRecoilState } from 'recoil';
import { User } from 'universal-authenticator-library';

type ActionMaker = (auth: PermissionLevel) => AnyAction | Array<AnyAction>;

export type SubmitActionFn = (am: ActionMaker) => Promise<void>;

export type AuthContext = {
    login: () => Promise<void>;
    logout: () => void;
    accountName: string | undefined;
    transact: SubmitActionFn;
};

const initialState: AuthContext = {
    login: async () => {},
    logout: () => {},
    accountName: undefined,
    transact: async () => {},
};

const internal = {
    activeUser: atom<User | null>({
        key: 'auth/internal/activeUser',
        default: null,
        dangerouslyAllowMutability: true,
    }),
};

export const authQueries = {
    activeUserName: selector({
        key: 'auth/authQueries/activeUserName',
        get: async ({ get }) => {
            const s = new URLSearchParams(window.location.search.slice(1));
            const impersonate = s.get('__impersonate_eos_name');
            if (impersonate) {
                return impersonate;
            }
            const user = get(internal.activeUser);
            return user?.getAccountName();
        },
    }),
};

const AuthReactContext = createContext<AuthContext>(initialState);

export function useAuth(): AuthContext {
    return useContext(AuthReactContext);
}

const chains = [{ chainId: config.chainId, nodeUrl: config.nodeUrl }];

const waxChain = {
    chainId: config.chainId,
    rpcEndpoints: [
        {
            protocol: 'https',
            host: config.apiUrl.substr('https://'.length),
            port: 443,
        },
    ],
};

const anchor = new Anchor([waxChain], {
    appName: config.appName,
});
const wcw = new Wax([waxChain]);

const wombat = new Wombat([waxChain], { appName: config.appName });

const authenticators = [anchor, wcw, wombat];

export class SignTransactionError extends Error {}

function UALConsumerInner(props: PropsWithChildren<{ ual: any }>) {
    const { ual, children } = props;

    const activeUser = ual.activeUser;

    const setUser = useSetRecoilState(internal.activeUser);
    const [accountName, setAccountName] = useState('');
    useEffect(() => {
        setUser(activeUser);
        const p = activeUser?.getAccountName();

        if (!!p) {
            p.then((v: string) => setAccountName(v));
        } else {
            setAccountName('');
        }
    }, [activeUser, setUser, setAccountName]);

    const auth: AuthContext = {
        login: async () => {
            ual.showModal();
        },
        logout: () => {
            ual.logout();
            setAccountName('');
            setUser(null);
        },
        accountName,
        transact: async (am: ActionMaker) => {
            if (!activeUser) {
                console.error('not logged in');
                return;
            }
            const accounts = activeUser?.scatter?.accounts;
            const permission =
                activeUser.requestPermission ||
                (accounts?.length > 0 ? accounts[0].authority : 'active');
            try {
                const actions = am({
                    actor: activeUser.accountName,
                    permission,
                } as PermissionLevel);
                await activeUser.signTransaction(
                    {
                        actions: Array.isArray(actions) ? actions : [actions],
                    },
                    { blocksBehind: 3, expireSeconds: 60 },
                );
                toast.success('Transaction executed locally');
            } catch (e) {
                console.log(e);
                toast.error('Error signing transaction: ' + e.toString());
                throw new SignTransactionError(e);
            }
        },
    };

    return (
        <AuthReactContext.Provider value={auth}>
            {children}
        </AuthReactContext.Provider>
    );
}

const UALConsumer = withUAL(UALConsumerInner);

export function AuthContextProvider(props: PropsWithChildren<any>) {
    return (
        <UALProvider
            chains={chains}
            authenticators={authenticators}
            appName={config.appName}
        >
            <UALConsumer>{props.children}</UALConsumer>
        </UALProvider>
    );
}
