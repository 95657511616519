import {
    createContext,
    PropsWithChildren,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState,
} from 'react';
import { config } from '../config';
import { useAuth } from './useAuth';
import { useRPC } from './useRPC';
import { toast } from 'react-toastify';

const UserLevelReactContext = createContext<{
    level: number;
    refresh: () => void;
}>({ level: 0, refresh: () => {} });

export function useUserLevel() {
    return useContext(UserLevelReactContext).level;
}

export function useRefreshUserLevel() {
    return useContext(UserLevelReactContext).refresh;
}

export function UserLevelProvider(props: PropsWithChildren<{}>) {
    const rpc = useRPC();
    const { accountName } = useAuth();

    const [needsRefresh, setNeedsRefresh] = useState(1);

    const [level, setLevel] = useState<number>(0);
    useEffect(() => {
        if (!accountName || needsRefresh < 0) {
            return;
        }
        rpc.get_table_rows({
            json: true,
            code: config.contractAccount,
            scope: config.contractAccount,
            table: 'users',
            lower_bound: accountName,
            upper_bound: accountName,
            limit: 1,
        })
            .then(({ rows }) => {
                if (rows.length > 0 && rows[0].owner === accountName) {
                    setLevel(rows[0].completed_sets);
                } else {
                    setLevel(0);
                }
            })
            .catch((e) =>
                toast.error('Failed to load your level: ' + e.toString()),
            );
    }, [rpc, accountName, needsRefresh]);

    const refresh = useCallback(() => {
        setNeedsRefresh((needsRefresh + 1) % 16);
    }, [setNeedsRefresh, needsRefresh]);

    const v = useMemo(() => ({ level, refresh }), [level, refresh]);

    return (
        <UserLevelReactContext.Provider value={v}>
            {props.children}
        </UserLevelReactContext.Provider>
    );
}
