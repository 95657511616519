import React from 'react';
import ReactDOM from 'react-dom';
import { ToastContainer } from 'react-toastify';
import { RecoilRoot } from 'recoil';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'react-toastify/dist/ReactToastify.css';
import './tailwind.generated.css';
import { AuthContextProvider } from './hooks/useAuth';
import { RewardProvider } from './hooks/useRewards';
import { UserLevelProvider } from './hooks/useUserLevel';
import { TokenBalanceProvider } from './hooks/useTokenBalance';
import { config } from './config';

ReactDOM.render(
    <React.StrictMode>
        <>
            <span className="hidden" aria-hidden>
                Net configured: {config.name}
            </span>
            <RecoilRoot>
                <AuthContextProvider>
                    <UserLevelProvider>
                        <RewardProvider>
                            <TokenBalanceProvider>
                                <App />
                            </TokenBalanceProvider>
                        </RewardProvider>
                    </UserLevelProvider>
                </AuthContextProvider>
            </RecoilRoot>
            <ToastContainer
                position="top-center"
                hideProgressBar
                closeOnClick
                pauseOnFocusLoss={false}
            />
        </>
    </React.StrictMode>,
    document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
