import { JsonRpc } from 'eosjs';
import { config } from '../config';
import { atom, selector, useRecoilState, useRecoilValue } from 'recoil';
import { localStorageEffect } from './store-effects';

const eosRPCEndpointAtom = atom({
    key: 'eos/rpc/endpoint',
    default: config.apiUrl,
    effects: [localStorageEffect('r/eosRPCEndpoint')],
});

export const eosRPCQuery = selector({
    key: 'eos/rpc',
    get: ({ get }) => {
        const endpoint = get(eosRPCEndpointAtom);
        return new JsonRpc(endpoint, { fetch: window.fetch });
    },
});

export function useRPC(): JsonRpc {
    return useRecoilValue(eosRPCQuery);
}

export function useRPCApiEndpointChanger() {
    return useRecoilState(eosRPCEndpointAtom);
}
