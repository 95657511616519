import { ABI } from './abi';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { IAsset } from 'atomicassets/build/API/Explorer/Objects';
import { config } from './config';
import { useRPC } from './hooks/useRPC';
import { SignTransactionError, useAuth } from './hooks/useAuth';
import { useExplorerApi } from './hooks/useExplorerApi';
import { useConfig } from './hooks/useConfig';
import { toast } from 'react-toastify';

type ScanState =
    | { tag: 'initial' }
    | { tag: 'loading'; loaded: number }
    | {
          tag: 'scanning';
          loaded: number;
          progress: number;
          found: number;
          frozen: number;
      }
    | {
          tag: 'scanned';
          loaded: number;
          frozen: number;
          nonIndexed: number;
          matches: ABI.NFT[];
      }
    | { tag: 'verifying'; loaded: number; matches: ABI.NFT[] };

export default function AssetScanner({
    targetMints,
    refreshGame,
}: {
    targetMints: { [key: string]: boolean | undefined };
    refreshGame: () => void;
}) {
    const [scanState, setScanState] = useState<ScanState>({ tag: 'initial' });

    const remainder = useMemo(
        () =>
            Object.entries(targetMints)
                .filter(([mint, f]) => f)
                .map(([mint]) => mint)
                .map((m) => parseInt(m, 10))
                .sort((a, b) => a - b),
        [targetMints],
    );

    const gameSize = remainder.length;
    useEffect(() => {
        setScanState({ tag: 'initial' });
    }, [gameSize]);

    const auth = useAuth();

    const startScan = useScanning(remainder, setScanState);

    const verifyAsset = async (assets: Array<ABI.NFT>) => {
        // https://discord.com/channels/733122024448458784/867103030516252713/893165360122064918
        const chunked = chunkArray(119, assets);

        for (let chunk of chunked) {
            // it is important to do this sequential, as parallel signing requests are not possible
            await auth.transact(
                ABI.verify({ owner: auth.accountName!, owned_assets: chunk }),
            );
        }

        refreshGame();
    };

    const scanAndVerify = async () => {
        try {
            const s = await startScan();
            setScanState(s);
            if (s.tag !== 'scanned') {
                toast.error('Invalid state. Please reload the page');
                setScanState({ tag: 'initial' });
                return;
            }

            if (s.matches.length === 0) {
                const f =
                    s.frozen === 0
                        ? ''
                        : `(${s.frozen} ${
                              s.frozen === 1 ? 'is' : 'are'
                          } still frozen)`;
                const ni =
                    s.nonIndexed === 0
                        ? ''
                        : `(${s.nonIndexed} ${
                              s.nonIndexed === 1 ? 'is' : 'are'
                          } not indexed yet)`;
                throw new Error(
                    `no assets with matching mints found ${f} ${ni}`.trim(),
                );
            }

            setScanState({
                tag: 'verifying',
                loaded: s.loaded,
                matches: s.matches,
            });
            await verifyAsset(s.matches);
            toast.success(`Verified ownership of ${s.matches.length} assets.`);
        } catch (e) {
            // SignTransactionError are already toasted.
            if (!(e instanceof SignTransactionError)) {
                toast.error(e.toString());
            }
        } finally {
            setScanState({ tag: 'initial' });
        }
    };

    if (scanState.tag === 'initial') {
        return (
            <div>
                <button onClick={scanAndVerify} className="btn-neon-yellow">
                    Scan Assets
                </button>
            </div>
        );
    }

    return (
        <div className="border-neon-yellow px-2 py-2 bg-dark bg-opacity-50 text-center text-white">
            {(() => {
                switch (scanState.tag) {
                    case 'loading':
                        return <>Loading {scanState.loaded} assets</>;
                    case 'scanning':
                        return (
                            <>
                                Found {scanState.found} matching mints,{' '}
                                {(scanState.progress * 100).toFixed(0)}% scanned
                            </>
                        );
                    case 'scanned':
                        return (
                            <>Found {scanState.matches.length} matching mints</>
                        );
                    case 'verifying':
                        return (
                            <>
                                Verifying ownership of{' '}
                                {scanState.matches.length} assets.
                            </>
                        );
                }
            })()}
        </div>
    );
}

export type frozenStatus =
    | { frozen: false; unfreezesAt: undefined }
    | { frozen: true; unfreezesAt: number };

export function useIsFrozen() {
    const rpc = useRPC();
    const cfg = useConfig();

    return async (id: string): Promise<frozenStatus> => {
        const { rows } = await rpc.get_table_rows({
            json: true,
            code: config.contractAccount,
            scope: config.contractAccount,
            table: 'frozen',
            lower_bound: id,
            upper_bound: id,
            limit: 1,
        });
        if (rows.length === 0) {
            return {
                frozen: false,
                unfreezesAt: undefined,
            };
        }

        const r = rows[0] as ABI._frozen_asset_entity;

        if (r.asset_id !== id) {
            return {
                frozen: false,
                unfreezesAt: undefined,
            } as const;
        }
        const now = new Date().valueOf();
        const frozenAt = Date.parse(r.time + 'Z');
        const unfreezesAt = frozenAt + parseInt(cfg.freeze_time, 10);

        const frozen = now < unfreezesAt;

        if (frozen) {
            return { frozen, unfreezesAt };
        }
        return { frozen: false, unfreezesAt: undefined };
    };
}

export function useUserOwnedAssetsLoader(
    setScanState: (s: ScanState) => void,
    options: { min_mint?: number; max_mint?: number } = {},
) {
    const cfg = useConfig();
    const auth = useAuth();
    const api = useExplorerApi();

    return async () => {
        const mint_offset = cfg.mint_offset;
        const lower_bound =
            (options.min_mint ?? cfg.min_mint) - mint_offset - 1;
        const upper_bound =
            (options.max_mint ?? cfg.max_mint) + mint_offset + 1;
        setScanState({ tag: 'loading', loaded: 0 });

        let hasMore = true;
        let nextPage = 1;
        const limit = 50;

        const assets: Array<IAsset> = [];

        let failSafe = 0;
        while (hasMore && failSafe < 2000) {
            failSafe++;

            const urlSearchParams = new URLSearchParams(
                window.location.hash.substring(1),
            );
            const owner =
                urlSearchParams.get('otherAccountToSearch') ?? auth.accountName;

            // typescript typings are not complete for this method call... :( just why...
            const res = await api.getAssets(
                {
                    // @ts-ignore
                    owner,
                    collection_name: config.collectionName,
                    min_template_mint: lower_bound,
                    max_template_mint: upper_bound,
                },
                nextPage,
                limit,
            );

            const matches = res.filter(
                (asset) => asset.schema.schema_name === config.schemaName,
            );

            assets.push(...matches);
            hasMore = res.length === limit;
            nextPage++;

            setScanState({ tag: 'loading', loaded: assets.length });
        }
        return assets;
    };
}

export function useFindIndex() {
    const rpc = useRPC();
    return useCallback(
        async (asset: IAsset): Promise<ABI.NFT> => {
            // find index by querying the `mints` table
            const { rows }: { rows: Array<ABI._mint_asset> } =
                await rpc.get_table_rows({
                    json: true,
                    code: config.contractAccount,
                    scope: config.contractAccount,
                    table: 'mints',
                    lower_bound: asset.template?.template_id,
                    upper_bound: asset.template?.template_id,
                    index_position: 2,
                    key_type: 'i64',
                    limit: 50,
                });
            const targetTemplateID = parseInt(asset.template!.template_id, 10);

            if (
                rows.length === 0 ||
                rows[0].template_id !== targetTemplateID ||
                rows[rows.length - 1].template_id !== targetTemplateID
            ) {
                return Promise.reject(
                    `no index found for asset: ${asset.asset_id} (Error-Code: E2MR)`,
                );
            }

            const row = rows.find(
                (row) =>
                    row.template_id === targetTemplateID &&
                    row.mints.some(
                        (m: { asset_id: string; mint: number }) =>
                            m.asset_id === asset.asset_id,
                    ),
            );

            if (!row) {
                return Promise.reject(
                    `no index found for asset: ${asset.asset_id} (Error-Code: E0RF)`,
                );
            }
            return {
                index: row.index,
                asset_id: asset.asset_id,
            } as ABI.NFT;
        },
        [rpc],
    );
}

export function useScanning(
    remainder: Array<number>,
    setScanState: (s: ScanState) => void,
) {
    const cfg = useConfig();
    const isFrozen = useIsFrozen();
    const findIndex = useFindIndex();

    const loadAssets = useUserOwnedAssetsLoader(setScanState, {
        min_mint: Math.min(...remainder),
        max_mint: Math.max(...remainder),
    });

    return async (): Promise<ScanState> => {
        const mint_offset = cfg.mint_offset;

        setScanState({ tag: 'loading', loaded: 0 });

        try {
            const assets = await loadAssets();

            const totalScans = remainder.length * assets.length;
            let scanned = 0;

            // this seems a bit inefficient, looping over the assets multiple times,
            // but there shouldn't be too many elements and this is now using the same
            // logic as the smart contract.
            // I think having the logic match between the two systems make it easier to reason about.
            const matches: Array<ABI.NFT> = [];
            const usedAssets = new Set<String>();
            const frozenAssets = new Set<String>();
            const nonIndexedAssets = new Set<String>();
            for (let elem of remainder) {
                let candidates: Array<{ mint: number; nft: ABI.NFT }> = [];
                for (let asset of assets) {
                    scanned++;

                    setScanState({
                        tag: 'scanning',
                        loaded: assets.length,
                        progress: scanned / totalScans,
                        found: matches.length,
                        frozen: frozenAssets.size,
                    });

                    if (
                        usedAssets.has(asset.asset_id) ||
                        frozenAssets.has(asset.asset_id)
                    ) {
                        continue;
                    }

                    const mint = parseInt(asset.template_mint, 10);
                    const difference = Math.abs(mint - elem);
                    if (difference > mint_offset) {
                        continue;
                    }

                    if ((await isFrozen(asset.asset_id)).frozen) {
                        frozenAssets.add(asset.asset_id);
                        continue;
                    }

                    let nft: ABI.NFT;
                    try {
                        nft = await findIndex(asset);
                    } catch (e) {
                        nonIndexedAssets.add(asset.asset_id);
                        continue;
                    }

                    if (difference === 0) {
                        candidates = [{ mint: elem, nft }];
                        break;
                    }
                    candidates.push({ mint: elem, nft });
                }

                if (candidates.length === 0) {
                    continue;
                }

                candidates.sort((a, b) => a.mint - b.mint);

                usedAssets.add(candidates[0].nft.asset_id);
                matches.push(candidates[0].nft);
            }
            return {
                tag: 'scanned',
                loaded: assets.length,
                matches,
                frozen: frozenAssets.size,
                nonIndexed: nonIndexedAssets.size,
            };
        } catch (e) {
            toast.error('Failed to scan assets: ' + e.toString());
            return { tag: 'initial' };
        }
    };
}

function chunkArray<T>(size: number, arr: Array<T>): Array<Array<T>> {
    const r = [];
    for (let i = 0; i < arr.length; i += size) {
        r.push(arr.slice(i, i + size));
    }
    return r;
}
