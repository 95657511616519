import { useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { config, connectOAuthClient } from './config';

export function ConnectCallbackComponent() {
    const [token, setToken] = useState('');

    useEffect(() => {
        (async () => {
            console.log('called');
            const token = await connectOAuthClient.token.getToken(
                document.location.href,
            );
            setToken(token.accessToken);
        })();
    });

    useEffect(() => {
        (async () => {
            fetch(
                'https://cors-anywhere.herokuapp.com/' +
                    config.connect.mintsEndpoint,
                {
                    method: 'POST',
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                    body: JSON.stringify({
                        mints: [25],
                    }),
                },
            );
        })();
    }, [token]);
    return <Redirect to="/" />;
}
