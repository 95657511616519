import { config } from '../config';
import { ExplorerApi } from 'atomicassets';
import { atom, selector, useRecoilState, useRecoilValue } from 'recoil';
import { localStorageEffect } from './store-effects';

const explorerEndpointAtom = atom({
    key: 'eos/explorerApi/endpoint',
    default: config.atomicExplorerEndpoint,
    effects: [localStorageEffect('r/explorerEndpoint')],
});

export const explorerApiQuery = selector({
    key: 'eos/explorerApi',
    get: ({ get }) => {
        return new ExplorerApi(get(explorerEndpointAtom), 'atomicassets', {
            fetch: (input, init) => fetch(input!, init),
        });
    },
});

export function useExplorerApi() {
    return useRecoilValue(explorerApiQuery);
}

export function useExplorerApiEndpointChanger() {
    return useRecoilState(explorerEndpointAtom);
}
