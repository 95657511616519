import { useCallback, useEffect, useMemo, useState } from 'react';
import { config } from './config';
import { ShopABI } from './abi';
import { IAsset, ITemplate } from 'atomicassets/build/API/Explorer/Objects';
import { useExplorerApi } from './hooks/useExplorerApi';
import { useAuth } from './hooks/useAuth';
import { toast } from 'react-toastify';
import AsyncButton from './AsyncButton';
import LoadingIndicator from './LoadingIndicator';
import IconAH from './icons/ah';
import { useRefreshTokenBalance } from './hooks/useTokenBalance';
import { AssetsSort, OrderParam } from 'atomicassets/build/API/Explorer/Enums';
import SolidSortDescending from './icons/solid-sort-descending';
import SolidSortAscending from './icons/solid-sort-ascending';
import { useRecoilRefresher_UNSTABLE, useRecoilValue } from 'recoil';
import {
    availableStoreEntriesQuery,
    shopTimeoutSecondsQuery,
    shopTimeoutUntilQuery,
} from './shopStore';

export default function Shop() {
    const auth = useAuth();
    const refreshTokenBalance = useRefreshTokenBalance();

    const storeEntries = useRecoilValue(availableStoreEntriesQuery);
    const timeoutUntil = useRecoilValue(shopTimeoutUntilQuery);
    const timeout = useRecoilValue(shopTimeoutSecondsQuery);

    const refresh = useRecoilRefresher_UNSTABLE(availableStoreEntriesQuery);

    const buy = async (id: string, templateID: string) => {
        if (!auth.accountName) {
            return auth.login();
        }
        const template = storeEntries[templateID];
        if (!template) {
            toast.error(
                'Could not find template ID or asset is not longer for sale.',
            );
            return;
        }
        await auth.transact(
            ShopABI.buy({
                asset_id: id,
                token_contract: template.store_entity.token_contract,
                buy_price: template.store_entity.buy_price,
            }),
        );
        refresh();
        refreshTokenBalance();
    };

    const [selectedSchema, setSelectedSchema] = useState<string>('');
    const [selectedTemplateID, setSelectedTemplateID] = useState<string>('');
    const [selectedRarity, setSelectedRarity] = useState<string>('');
    const [sortOrder, setSetOrder] = useState(OrderParam.Desc);

    const selectableSchemas = useMemo(() => {
        return Array.from(
            new Set(
                Object.values(storeEntries)
                    .map((e) => e.template?.schema.schema_name)
                    .filter((s) => !!s),
            ),
        );
    }, [storeEntries]);

    const selectableRarities = useMemo(() => {
        const rarityOrder = ['Common', 'Uncommon', 'Rare', 'Epic', 'Legendary'];
        const rarities = Array.from(
            new Set(
                Object.values(storeEntries)
                    .filter(
                        ({ template }) =>
                            !selectedSchema ||
                            template?.schema.schema_name === selectedSchema,
                    )
                    .map((e) => e.template?.immutable_data['rarity']),
            ),
        );
        rarities.sort(
            (a, b) => rarityOrder.indexOf(a) - rarityOrder.indexOf(b),
        );
        return rarities;
    }, [selectedSchema, storeEntries]);
    useEffect(() => {
        if (!selectedRarity) return;
        if (selectableRarities.includes(selectedRarity)) return;
        setSelectedRarity('');
    }, [selectableRarities, selectedRarity, setSelectedRarity]);

    const selectableTemplates = useMemo(() => {
        return Object.values(storeEntries)
            .map(({ template }) => template)
            .filter((t): t is ITemplate => !!t)
            .filter((t) => {
                const schemaMatches =
                    !selectedSchema || t.schema.schema_name === selectedSchema;
                const rarityMatches =
                    !selectedRarity ||
                    t.immutable_data['rarity'] === selectedRarity;

                return schemaMatches && rarityMatches;
            });
    }, [storeEntries, selectedSchema, selectedRarity]);
    useEffect(() => {
        if (!selectedTemplateID) return;
        if (
            selectableTemplates
                .map((t) => t.template_id)
                .includes(selectedTemplateID)
        )
            return;
        setSelectedTemplateID('');
    }, [selectableTemplates, selectedTemplateID, setSelectedTemplateID]);

    const templateWhitelist = useMemo(() => {
        if (selectedTemplateID) {
            return [selectedTemplateID];
        }
        return Object.values(storeEntries)
            .filter(
                (e) =>
                    !selectedSchema ||
                    e.template?.schema.schema_name === selectedSchema,
            )
            .map((e) => e.store_entity.template_id);
    }, [selectedTemplateID, storeEntries, selectedSchema]);

    const { assets, loading, hasMore, error, loadNext } = useShopAssets(
        templateWhitelist,
        selectedRarity,
        sortOrder,
    );

    const anyLoading = loading;
    return (
        <div className="mx-auto container max-w-7xl flex flex-col items-center space-y-4 overflow-hidden p-8">
            <div className="flex flex-col md:flex-row justify-start items-center space-y-2 md:space-y-0 md:space-x-4 mb-4">
                <select
                    value={selectedSchema}
                    onChange={(e) => setSelectedSchema(e.target.value)}
                    className="bg-dark text-white border-yellow"
                >
                    <option value="">All schemas</option>
                    {selectableSchemas.map((schema) => (
                        <option key={schema} value={schema}>
                            {schema}
                        </option>
                    ))}
                </select>
                <select
                    value={selectedRarity}
                    onChange={(e) => setSelectedRarity(e.target.value)}
                    className="bg-dark text-white border-yellow"
                >
                    <option value="">All rarities</option>
                    {selectableRarities.map((r) => (
                        <option key={r} value={r}>
                            {r}
                        </option>
                    ))}
                </select>
                <select
                    value={selectedTemplateID}
                    onChange={(e) => setSelectedTemplateID(e.target.value)}
                    className="bg-dark text-white border-yellow"
                >
                    <option value="">All templates</option>
                    {selectableTemplates.map((template) => (
                        <option
                            key={template.template_id}
                            value={template.template_id}
                        >
                            [
                            {template.schema.schema_name === 'monkeystacks'
                                ? 'S'
                                : template.schema.schema_name === 'packs'
                                ? 'P'
                                : template.schema.schema_name === 'crptomonkeys'
                                ? 'C'
                                : '?'}
                            ] {template.immutable_data.name} (
                            {
                                storeEntries[template.template_id].store_entity
                                    .buy_price
                            }
                            )
                        </option>
                    ))}
                </select>
                <button
                    onClick={() =>
                        setSetOrder(
                            sortOrder === OrderParam.Asc
                                ? OrderParam.Desc
                                : OrderParam.Asc,
                        )
                    }
                    className="text-white flex flex-row items-center space-x-2 bg-dark bg-opacity-50 hover:bg-opacity-25 rounded px-4 py-2"
                >
                    <span>Sort</span>{' '}
                    {sortOrder === OrderParam.Asc ? (
                        <SolidSortAscending />
                    ) : (
                        <SolidSortDescending />
                    )}
                </button>
            </div>
            {anyLoading && <LoadingIndicator />}
            {timeout > 0 && (
                <p className="text-white bg-dark px-4 py-1">
                    You can buy from the store once every{' '}
                    {(timeout / 3600).toFixed(1)} hours.
                </p>
            )}
            {timeoutUntil && (
                <p className="text-white bg-dark px-4 py-1">
                    Next buy possible at {timeoutUntil.toLocaleString()}
                </p>
            )}
            {error && <p className="text-white">{error.toString()}</p>}
            <div className="w-full overflow-y-auto h-full flex flex-col space-x-2 bg-dark bg-opacity-25 border-neon-purple px-4 py-6">
                <div className="w-full flex flex-wrap items-center justify-center">
                    {!anyLoading && assets.length === 0 && (
                        <div className="w-full flex justify-center">
                            <span className="text-white font-medium text-center px-2 py-4">
                                There aren't any assets for sale right now.
                            </span>
                        </div>
                    )}
                    {assets.map((a) => (
                        <div
                            key={a.asset_id}
                            className="relative m-2 rounded overflow-hidden flex flex-col items-stretch bg-dark bg-opacity-50"
                        >
                            <div className="absolute top-0 left-0 p-2">
                                <div className="bg-dark rounded px-2 py-1 text-white text-xs">
                                    #{a.template_mint}
                                </div>
                            </div>
                            <div className="absolute top-0 right-0 p-2">
                                <a
                                    className="block bg-dark rounded px-2 py-1 text-atomic text-xs"
                                    href={
                                        config.atomichubBaseUrl +
                                        '/explorer/asset/' +
                                        a.asset_id
                                    }
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <IconAH className="w-4 h-4" />
                                </a>
                            </div>
                            <div className="flex-grow-0 bg-dark h-56">
                                <img
                                    src={
                                        'https://cloudflare-ipfs.com/ipfs/' +
                                        a.template?.immutable_data?.img
                                    }
                                    className="h-full w-full object-cover"
                                    alt="NFT artwork"
                                />
                            </div>
                            <div className="px-2 py-2">
                                <span className="font-bold text-white">
                                    {a.template?.immutable_data?.name ?? '???'}
                                </span>
                            </div>
                            <div className="flex flex-row items-center justify-between text-white px-2 py-2">
                                <div className="text-sm">
                                    {storeEntries[a.template?.template_id ?? '']
                                        ?.store_entity.buy_price ?? '-'}
                                </div>
                                <AsyncButton
                                    className="btn-neon-yellow"
                                    onClick={() =>
                                        buy(
                                            a.asset_id,
                                            a.template?.template_id ?? '',
                                        )
                                    }
                                >
                                    BUY
                                </AsyncButton>
                            </div>
                        </div>
                    ))}
                </div>
                {!anyLoading && hasMore && (
                    <button
                        className="btn-neon-yellow"
                        onClick={() => loadNext()}
                    >
                        Load More
                    </button>
                )}
            </div>
        </div>
    );
}

function useShopAssets(
    templateIDs: Array<string>,
    rarity: string,
    sortOrder: OrderParam,
) {
    const api = useExplorerApi();
    const [nextPage, setNextPage] = useState(0);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<Error | undefined>(undefined);
    const [assets, setAssets] = useState<Array<IAsset>>([]);
    const [hasMore, setHasMore] = useState(true);
    const loadNext = useCallback(() => {
        setNextPage(nextPage + 1);
    }, [nextPage, setNextPage]);

    const templateWhitelist = useMemo(
        () => templateIDs.join(','),
        [templateIDs],
    );

    const [
        currentPaginationTemplateWhitelist,
        setCurrentPaginationTemplateWhitelist,
    ] = useState('');
    const [currentRarity, setCurrentRarity] = useState('');
    const [currentSortOrder, setCurrentSortOder] = useState(sortOrder);

    const limit = 20;

    const fetch = async (
        template_whitelist: string,
        order: OrderParam,
        page: number,
        rarity: string,
    ) => {
        if (template_whitelist.length === 0) {
            return;
        }

        setLoading(true);
        setError(undefined);

        try {
            const needsClearing =
                template_whitelist !== currentPaginationTemplateWhitelist ||
                rarity !== currentRarity ||
                order !== currentSortOrder;

            if (needsClearing) {
                setNextPage(0);
                page = 0;
            }
            // typescript typings are not complete for this method call... :( just why...
            const res = await api.getAssets(
                {
                    // @ts-ignore
                    owner: config.shop.contractAccount,
                    template_whitelist,
                    sort: AssetsSort.Minted,
                    order,
                },
                // one based indexing
                page + 1,
                limit,
                rarity
                    ? [
                          {
                              key: 'rarity',
                              value: rarity,
                              type: 'data',
                          },
                      ]
                    : undefined,
            );

            const nextAssets = needsClearing ? res : [...assets, ...res];

            setAssets(nextAssets);
            setCurrentPaginationTemplateWhitelist(template_whitelist);
            setCurrentRarity(rarity);
            setCurrentSortOder(sortOrder);
            setHasMore(res.length === limit);
        } catch (e) {
            setError(e);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        void fetch(templateWhitelist, sortOrder, nextPage, rarity);
        // eslint-disable-next-line
    }, [templateWhitelist, sortOrder, nextPage, rarity]);

    return { loading, error, assets, hasMore, loadNext };
}
