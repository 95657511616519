import {
    createContext,
    PropsWithChildren,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState,
} from 'react';
import { config } from '../config';
import { useAuth } from './useAuth';
import { useRPC } from './useRPC';
import { toast } from 'react-toastify';

const TokenBalanceReactContext = createContext<{
    balance: string;
    refresh: () => void;
}>({ balance: '0', refresh: () => {} });

export function useTokenBalance() {
    return useContext(TokenBalanceReactContext).balance;
}

export function useRefreshTokenBalance() {
    return useContext(TokenBalanceReactContext).refresh;
}

export function TokenBalanceProvider(props: PropsWithChildren<{}>) {
    const rpc = useRPC();
    const { accountName } = useAuth();

    const [needsRefresh, setNeedsRefresh] = useState(1);

    const [balance, setBalance] = useState<string>('');
    useEffect(() => {
        if (!accountName || needsRefresh < 0) {
            return;
        }

        rpc.get_currency_balance(config.token.contractAccount, accountName)
            .then((res) => {
                if (res !== undefined && res !== null && res.length > 0) {
                    setBalance(res[0]);
                } else {
                    setBalance('');
                }
            })
            .catch((error) => {
                toast.error(
                    'Could not fetch token balance: ' + error.toString(),
                );
            });
    }, [rpc, accountName, needsRefresh]);

    const refresh = useCallback(() => {
        setNeedsRefresh((needsRefresh + 1) % 16);
    }, [setNeedsRefresh, needsRefresh]);

    const v = useMemo(() => ({ balance, refresh }), [balance, refresh]);

    return (
        <TokenBalanceReactContext.Provider value={v}>
            {props.children}
        </TokenBalanceReactContext.Provider>
    );
}
