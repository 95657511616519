import { useAuth } from './hooks/useAuth';
import { useMemo } from 'react';
import { useCurrentGame } from './hooks/useCurrentGame';
import { ABI } from './abi';
import AssetScanner from './AssetScanner';
import CurrentGameDisplay from './CurrentGameDisplay';
import { useRefreshUserLevel } from './hooks/useUserLevel';
import { useRefreshTokenBalance } from './hooks/useTokenBalance';
import LoadingIndicator from './LoadingIndicator';
import ExpertMenu from './ExpertMenu';
import { useIsContractInMaintenance } from './hooks/useConfig';

export default function GameScreen() {
    const auth = useAuth();

    const isInMaintenance = useIsContractInMaintenance();

    const [currentGame, newGame, refreshGame, isLoading] = useCurrentGame();
    const refreshLevel = useRefreshUserLevel();
    const refreshTokenBalance = useRefreshTokenBalance();

    const completeGame = async () => {
        await auth.transact(ABI.complete({ owner: auth.accountName! }));
        refreshGame();
        refreshLevel();
        refreshTokenBalance();
    };

    const targetMints = useMemo((): { [key: string]: boolean | undefined } => {
        if (!currentGame) {
            return {};
        }
        return currentGame.to_collect.reduce(
            (acc, v) => ({ [v]: !currentGame.collected.includes(v), ...acc }),
            {},
        );
    }, [currentGame]);

    const canCompleteGame = useMemo(() => {
        const size = currentGame?.to_collect?.length ?? 0;
        const required = size - Math.ceil(Math.log10(size));
        return (currentGame?.collected?.length ?? 0) >= required;
    }, [currentGame]);

    if (isInMaintenance) {
        return (
            <div className="mx-auto container text-center text-white">
                <span>monKeymatching is currently under maintenance.</span>
            </div>
        );
    }

    return (
        <>
            <div className="mx-auto container max-w-7xl flex flex-col items-center space-y-4 overflow-hidden p-8">
                {!!currentGame && (
                    <CurrentGameDisplay
                        game={currentGame}
                        targetMints={targetMints}
                    />
                )}
                {isLoading && <LoadingIndicator />}
                <div className="flex-shrink-0 flex flex-col items-center space-y-2">
                    {!currentGame && (
                        <button
                            className="btn-neon-yellow flicker"
                            onClick={newGame}
                        >
                            Start new Game
                        </button>
                    )}
                    {canCompleteGame && (
                        <button
                            className="btn-neon-yellow"
                            onClick={completeGame}
                        >
                            Complete and collect Reward
                        </button>
                    )}
                    {!!currentGame && (
                        <AssetScanner
                            targetMints={targetMints}
                            refreshGame={refreshGame}
                        />
                    )}
                </div>
            </div>
            <div className="absolute bottom-0 inset-x-0 flex flex-row space-x-2 justify-end px-4 py-2">
                <ExpertMenu currentGame={currentGame} newGame={newGame} />
            </div>
        </>
    );
}
