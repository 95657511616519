import { NavLink } from 'react-router-dom';
import { useAuth } from './hooks/useAuth';
import { useUserLevel } from './hooks/useUserLevel';
import { useTokenBalance } from './hooks/useTokenBalance';
import mmIcon from './mm_icon.png';

export default function Nav() {
    const auth = useAuth();
    const level = useUserLevel();
    const balance = useTokenBalance();
    return (
        <div className="w-full px-4 py-6">
            <nav className="w-full text-white px-4 py-2 border-neon-yellow bg-dark bg-opacity-50">
                <div className="mx-auto container flex flex-row space-x-4 items-center">
                    <img
                        src={mmIcon}
                        alt="monKeymatching Icon"
                        className="w-12"
                    />
                    {auth.accountName && <span>Level: {level}</span>}
                    <NavLink
                        to="/"
                        exact
                        activeClassName="bg-dark text-white"
                        className="text-gray-100 hover:bg-dark hover:text-white px-3 py-2 rounded-md text-sm font-medium"
                    >
                        monKeymatch
                    </NavLink>
                    <NavLink
                        to="/shop"
                        activeClassName="bg-dark text-white"
                        className="text-gray-100 hover:bg-dark hover:text-white px-3 py-2 rounded-md text-sm font-medium"
                    >
                        monKeyshop
                    </NavLink>
                    <NavLink
                        to="/burn"
                        activeClassName="bg-dark text-white"
                        className="text-gray-100 hover:bg-dark hover:text-white px-3 py-2 rounded-md text-sm font-medium"
                    >
                        monKeyburn
                    </NavLink>
                    <div className="flex-grow" />
                    {auth.accountName && (
                        <div className="flex flex-col items-end">
                            <span className="font-bold">
                                {auth.accountName}
                            </span>
                            {balance && (
                                <span className="text-xs">{balance}</span>
                            )}
                        </div>
                    )}
                    {auth.accountName ? (
                        <button
                            onClick={(e) => auth.logout()}
                            className="px-2 py-1 font-bold text-white hover:text-gray-100"
                        >
                            Logout
                        </button>
                    ) : (
                        <button
                            onClick={auth.login}
                            className="btn-neon-yellow flicker"
                        >
                            Login
                        </button>
                    )}
                </div>
                <span className="hidden">{process.env.REACT_APP_GIT_SHA}</span>
            </nav>
        </div>
    );
}
