export default function SolidSortDescending(props: { className?: string }) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            className={('h-5 w-5 ' + props.className).trim()}
            viewBox="0 0 20 20"
            fill="currentColor"
        >
            <path d="M3 3a1 1 0 000 2h11a1 1 0 100-2H3zM3 7a1 1 0 000 2h7a1 1 0 100-2H3zM3 11a1 1 0 100 2h4a1 1 0 100-2H3zM15 8a1 1 0 10-2 0v5.586l-1.293-1.293a1 1 0 00-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L15 13.586V8z" />
        </svg>
    );
}
