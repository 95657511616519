import { AnyAction, PermissionLevel } from '@greymass/eosio';
import { config } from './config';

export namespace ABI {
    type uint64 = string;
    type uint16 = number;
    type name = string;
    type time_point = string;

    export type NFT = {
        asset_id: uint64;
        index: uint64;
    };

    export type MINT = {
        asset_id: uint64;
        mint: uint16;
    };

    export type _frozen_asset_entity = {
        asset_id: uint64;
        owner: name;
        time: time_point;
    };

    export type _game_entity = {
        owner: name;
        to_collect: uint16[];
        collected: uint16[];
        time: time_point;
    };

    export type _user_entity = {
        owner: name;
        completed_sets: uint64;
    };

    export type _config_entity = {
        maintenance: boolean;
        salt: string;
        params: cfg_params;
    };

    export type _mint_asset = {
        index: uint64;
        template_id: number;
        mints: Array<MINT>;
    };

    export type _reward_entity = {
        completions: uint64;
        contract: name;
        amount: string;
    };

    export type cfg_params = {
        new_game_base: uint16;
        reward_cap: uint16;
        min_mint: uint16;
        max_mint: uint16;
        mint_offset: uint16;
        freeze_time: uint64;
        regeneration_cd: uint64;
        reward_memo: string;
    };

    export const newgame =
        (data: { owner: name }) =>
        (authorization: PermissionLevel): AnyAction => ({
            account: config.contractAccount,
            name: 'newgame',
            authorization: [authorization],
            data,
        });

    export const verify =
        (data: { owner: name; owned_assets: NFT[] }) =>
        (authorization: PermissionLevel): AnyAction => ({
            account: config.contractAccount,
            name: 'verify',
            authorization: [authorization],
            data,
        });

    export const complete =
        (data: { owner: name }) =>
        (authorization: PermissionLevel): AnyAction => ({
            account: config.contractAccount,
            name: 'complete',
            authorization: [authorization],
            data,
        });

    export const unfreezeall =
        (data: { owner: name }) =>
        (authorization: PermissionLevel): AnyAction => ({
            account: config.contractAccount,
            name: 'unfreezeall',
            authorization: [authorization],
            data,
        });
}

export namespace ShopABI {
    type uint64 = string;
    type uint32 = number;
    type name = string;
    type bool = boolean;
    type asset = string;
    type time_point_sec = string;

    export type cfg_params = {
        timeout_seconds: uint32;
    };

    export type _config_entity = {
        maintenance: bool;
        params: cfg_params;
    };

    export type _users_entity = {
        user: name;
        last_buy: time_point_sec;
    };

    export type _store_entity = {
        template_id: uint64;
        token_contract: name;
        buy_price: asset;
        burn_token: bool;
    };

    export const buy =
        (data: { asset_id: string; token_contract: name; buy_price: asset }) =>
        (authorization: PermissionLevel): AnyAction => ({
            account: data.token_contract,
            name: 'transfer',
            authorization: [authorization],
            data: {
                from: authorization.actor,
                to: config.shop.contractAccount,
                quantity: data.buy_price,
                memo: `buy:${data.asset_id}`,
            },
        });
}

export namespace BurnerABI {
    type uint32 = number;
    type name = string;
    type bool = boolean;
    type asset = string;

    export type _burnable_entity = {
        template_id: uint32;
        price: { quantity: asset; contract: name };
        burn_nft: bool;
        capacity: uint32;
    };
}
