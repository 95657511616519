import { config } from '../config';
import { ABI } from '../abi';
import { eosRPCQuery } from './useRPC';
import { selector, useRecoilValue } from 'recoil';

const configQuery = selector({
    key: 'config',
    get: async ({ get }) => {
        const rpc = get(eosRPCQuery);

        const res = await rpc.get_table_rows({
            json: true,
            code: config.contractAccount,
            scope: config.contractAccount,
            table: 'config',
            limit: 1,
        });

        return res.rows[0] as ABI._config_entity;
    },
});

export function useConfig(): ABI.cfg_params {
    return useRecoilValue(configQuery).params;
}

export function useIsContractInMaintenance(): boolean {
    const cfg = useRecoilValue(configQuery);
    if (cfg.maintenance) {
        return true;
    }

    const range = cfg.params.max_mint - cfg.params.min_mint;
    return range < 0 || range < cfg.params.mint_offset;
}
