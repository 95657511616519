import { Menu, Transition } from '@headlessui/react';
import { Fragment, useCallback, useMemo, useState } from 'react';
import cx from 'classnames';
import { ABI } from './abi';
import { useAuth } from './hooks/useAuth';
import { Game } from './hooks/useCurrentGame';
import { useConfig } from './hooks/useConfig';
import { useUserOwnedAssetsLoader } from './AssetScanner';
import { toast } from 'react-toastify';
import FrozenCheckDialog from './FrozenCheckDialog';
import EndpointChooserDialog from './EndpointChooserDialog';
import ApiEndpointChooserDialog from './ApiEndpointChooserDialog';
import IndexingCheckDialog from './IndexingCheckDialog';

type Props = {
    currentGame: Game | undefined;
    newGame: () => Promise<void>;
};

export default function ExpertMenu({ currentGame, newGame }: Props) {
    const auth = useAuth();
    const cfg = useConfig();

    const canRestart = useMemo(() => {
        if (!currentGame) {
            return false;
        }
        const now = new Date().valueOf();
        const startedAt = Date.parse(currentGame.time + 'Z');
        const restartableAt = startedAt + parseInt(cfg.regeneration_cd, 10);
        return now > restartableAt;
    }, [currentGame, cfg]);

    const restartGame = useCallback(async () => {
        if (
            // eslint-disable-next-line no-restricted-globals
            confirm(
                'Resetting the game will reset the progress for your current level and generate a completely new set of mints.',
            )
        ) {
            await newGame();
        }
    }, [newGame]);

    const loadAssets = useUserOwnedAssetsLoader(() => {});

    const copyUsersMintsToClipboard = async () => {
        const id = toast.info('Loading assets', {
            autoClose: false,
        });
        try {
            const assets = await loadAssets();
            const uniqueMints = Array.from(
                new Set(assets.map((a) => a.template_mint)).values(),
            ).sort((a, b) => parseInt(a, 10) - parseInt(b, 10));

            await navigator.clipboard.writeText(uniqueMints.join(','));
            toast.success('Mints copied to clipboard');
        } catch (e) {
            toast.error('Failed to load your assets: ' + e.toString());
        } finally {
            toast.dismiss(id);
        }
    };

    const [frozenCheckOpen, setFrozenCheckOpen] = useState(false);
    const [indexCheckOpen, setIndexCheckOpen] = useState(false);
    const [endpointChooserOpen, setEndpointChooserOpen] = useState(false);
    const [apiEndpointChooserOpen, setApiEndpointChooserOpen] = useState(false);

    // const startConnectFlow = () => {
    //     document.location.href = connectOAuthClient.token.getUri();
    // };

    const openCardamon = () => {
        window.open(`https://cardamon.link`, '_blank', 'noopener noreferrer');
    };

    return (
        <>
            <Menu as="div" className="relative inline-block text-left">
                <div>
                    <Menu.Button className="inline-flex justify-center w-full px-4 py-2 text-sm font-medium text-white bg-black rounded-md bg-opacity-20 hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75">
                        Toolbox
                    </Menu.Button>
                </div>
                <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                >
                    <Menu.Items className="absolute right-0 bottom-0 w-64 origin-bottom-right bg-dark divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                        <div className="px-1 py-1">
                            <Menu.Item>
                                {({ active }) => (
                                    <button
                                        className={cx(
                                            'group flex rounded-md items-center w-full px-2 py-2 text-sm',
                                            {
                                                'text-yellow': active,
                                                'text-gray-200': !active,
                                            },
                                        )}
                                        onClick={() =>
                                            auth.transact(
                                                ABI.unfreezeall({
                                                    owner: auth.accountName!,
                                                }),
                                            )
                                        }
                                    >
                                        Freeup RAM
                                    </button>
                                )}
                            </Menu.Item>
                            <Menu.Item>
                                {({ active }) => (
                                    <button
                                        className={cx(
                                            'group flex rounded-md items-center w-full px-2 py-2 text-sm',
                                            {
                                                'text-yellow': active,
                                                'text-gray-200': !active,
                                            },
                                        )}
                                        onClick={() => setFrozenCheckOpen(true)}
                                    >
                                        Check Frozen Status
                                    </button>
                                )}
                            </Menu.Item>
                            <Menu.Item>
                                {({ active }) => (
                                    <button
                                        className={cx(
                                            'group flex rounded-md items-center w-full px-2 py-2 text-sm',
                                            {
                                                'text-yellow': active,
                                                'text-gray-200': !active,
                                            },
                                        )}
                                        onClick={() => setIndexCheckOpen(true)}
                                    >
                                        Check Index Status
                                    </button>
                                )}
                            </Menu.Item>
                            {canRestart && (
                                <Menu.Item>
                                    {({ active }) => (
                                        <button
                                            className={cx(
                                                'group flex rounded-md items-center w-full px-2 py-2 text-sm',
                                                {
                                                    'text-yellow': active,
                                                    'text-gray-200': !active,
                                                },
                                            )}
                                            onClick={restartGame}
                                        >
                                            Restart current level
                                        </button>
                                    )}
                                </Menu.Item>
                            )}
                            <Menu.Item>
                                {({ active }) => (
                                    <button
                                        className={cx(
                                            'group flex rounded-md items-center w-full px-2 py-2 text-sm',
                                            {
                                                'text-yellow': active,
                                                'text-gray-200': !active,
                                            },
                                        )}
                                        onClick={copyUsersMintsToClipboard}
                                    >
                                        Copy mints I own
                                    </button>
                                )}
                            </Menu.Item>
                            <Menu.Item>
                                {({ active }) => (
                                    <button
                                        className={cx(
                                            'group flex rounded-md items-center w-full px-2 py-2 text-sm',
                                            {
                                                'text-yellow': active,
                                                'text-gray-200': !active,
                                            },
                                        )}
                                        onClick={() =>
                                            setEndpointChooserOpen(true)
                                        }
                                    >
                                        Change AH Endpoint
                                    </button>
                                )}
                            </Menu.Item>
                            <Menu.Item>
                                {({ active }) => (
                                    <button
                                        className={cx(
                                            'group flex rounded-md items-center w-full px-2 py-2 text-sm',
                                            {
                                                'text-yellow': active,
                                                'text-gray-200': !active,
                                            },
                                        )}
                                        onClick={() =>
                                            setApiEndpointChooserOpen(true)
                                        }
                                    >
                                        Change Api Endpoint
                                    </button>
                                )}
                            </Menu.Item>
                            <Menu.Item>
                                {({ active }) => (
                                    <button
                                        className={cx(
                                            'group flex rounded-md items-center w-full px-2 py-2 text-sm',
                                            {
                                                'text-yellow': active,
                                                'text-gray-200': !active,
                                            },
                                        )}
                                        onClick={openCardamon}
                                    >
                                        Find trade partners on Card-a-Mon
                                    </button>
                                )}
                            </Menu.Item>
                        </div>
                    </Menu.Items>
                </Transition>
            </Menu>
            <FrozenCheckDialog
                isOpen={frozenCheckOpen}
                requestClose={() => setFrozenCheckOpen(false)}
            />
            <IndexingCheckDialog
                isOpen={indexCheckOpen}
                requestClose={() => setIndexCheckOpen(false)}
            />
            <EndpointChooserDialog
                isOpen={endpointChooserOpen}
                requestClose={() => setEndpointChooserOpen(false)}
            />
            <ApiEndpointChooserDialog
                isOpen={apiEndpointChooserOpen}
                requestClose={() => setApiEndpointChooserOpen(false)}
            />
        </>
    );
}
