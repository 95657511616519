import { AtomEffect } from 'recoil';

export const localStorageEffect =
    <T>(key: string): AtomEffect<T> =>
    ({ setSelf, onSet }) => {
        const savedValue = localStorage.getItem(key);
        if (
            savedValue !== null &&
            savedValue !== undefined &&
            savedValue !== 'undefined'
        ) {
            setSelf(JSON.parse(savedValue));
        }

        onSet((newValue, _, isReset) => {
            isReset
                ? localStorage.removeItem(key)
                : localStorage.setItem(key, JSON.stringify(newValue));
        });
    };
