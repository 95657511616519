import { Suspense } from 'react';
import { useAuth } from './hooks/useAuth';
import GameScreen from './GameScreen';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Nav from './Nav';
import Shop from './Shop';
import { ConnectCallbackComponent } from './connect';
import Burner from './Burner';
import LoadingIndicator from './LoadingIndicator';
import ErrorBoundary from './ErrorBoundary';
import ApiEndpointChooserDialog from './ApiEndpointChooserDialog';
import EndpointChooserDialog from './EndpointChooserDialog';

function App() {
    const auth = useAuth();
    return (
        <BrowserRouter>
            <div className="flex flex-col h-screen relative">
                <Nav />
                <ErrorBoundary>
                    <Suspense fallback={<LoadingIndicator />}>
                        <Switch>
                            <Route path="/" exact>
                                {auth.accountName ? (
                                    <GameScreen />
                                ) : (
                                    <span className="text-center text-white">
                                        Please login to start matching
                                    </span>
                                )}
                            </Route>
                            <Route path="/shop">
                                <Shop />
                            </Route>
                            <Route path="/burn">
                                <Burner />
                            </Route>
                            <Route path="/match/noexit/callback" exact>
                                <ConnectCallbackComponent />
                            </Route>
                            <Route path="/endpoint/rpc">
                                <ApiEndpointChooserDialog
                                    isOpen
                                    requestClose={() => void 0}
                                />
                            </Route>
                            <Route path="/endpoint/atomic">
                                <EndpointChooserDialog
                                    isOpen
                                    requestClose={() => void 0}
                                />
                            </Route>
                        </Switch>
                    </Suspense>
                </ErrorBoundary>
            </div>
        </BrowserRouter>
    );
}

export default App;
