import { useEffect, useState } from 'react';
import { config } from '../config';
import { useAuth } from './useAuth';
import { ABI } from '../abi';
import { useRPC } from './useRPC';
import { toast } from 'react-toastify';

export type Game = ABI._game_entity;

export function useCurrentGame() {
    const rpc = useRPC();
    const { accountName, transact } = useAuth();

    const [loading, setLoading] = useState(false);
    const [needsRefresh, setNeedsRefresh] = useState(1);
    const [currentGame, setCurrentGame] = useState<Game | undefined>(undefined);
    useEffect(() => {
        if (!accountName || needsRefresh < 0) {
            return;
        }
        setLoading(true);
        rpc.get_table_rows({
            json: true,
            code: config.contractAccount,
            scope: config.contractAccount,
            table: 'games',
            lower_bound: accountName,
            upper_bound: accountName,
            limit: 1,
        })
            .then(({ rows }) => {
                if (rows.length > 0 && rows[0].owner === accountName) {
                    setCurrentGame(rows[0]);
                } else {
                    setCurrentGame(undefined);
                }
            })
            .catch((e) => {
                toast.error('Failed to load current game: ' + e.toString());
            })
            .finally(() => setLoading(false));
    }, [rpc, accountName, needsRefresh, setLoading]);

    const newGame = async () => {
        await transact(ABI.newgame({ owner: accountName! }));
        refresh();
    };

    const refresh = () => {
        setTimeout(() => {
            setNeedsRefresh((needsRefresh + 1) % 16);
        }, 500);
    };

    return [currentGame, newGame, refresh, loading] as const;
}
