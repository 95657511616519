import {
    createContext,
    PropsWithChildren,
    useContext,
    useEffect,
    useState,
} from 'react';
import { config } from '../config';
import { ABI } from '../abi';
import { useRPC } from './useRPC';
import { useConfig } from './useConfig';
import { toast } from 'react-toastify';

export type RewardLevels = { [k: string]: string };

const initialState: RewardLevels = {};

const RewardReactContext = createContext<RewardLevels>(initialState);

export function useRewardForCompletions(completions: number) {
    const levels = useContext(RewardReactContext);
    const cfg = useConfig();

    const amount = levels[completions];
    if (!amount) {
        return levels[cfg?.reward_cap || 0];
    }
    return amount;
}

export function RewardProvider(props: PropsWithChildren<{}>) {
    const rpc = useRPC();

    const [rewardLevels, setRewardLevels] = useState<{ [k: string]: string }>(
        {},
    );

    useEffect(() => {
        rpc.get_table_rows({
            json: true,
            code: config.contractAccount,
            scope: config.contractAccount,
            table: 'rewards',
        })
            .then(({ rows }) =>
                rows.map((row: ABI._reward_entity) => [
                    parseInt(row.completions, 10),
                    row.amount,
                ]),
            )
            .then((entries) => Object.fromEntries(entries))
            .then(setRewardLevels)
            .catch((e) =>
                toast.error(
                    'Failed load to rewards for current game: ' + e.toString(),
                ),
            );
    }, [rpc]);

    return (
        <RewardReactContext.Provider value={rewardLevels}>
            {props.children}
        </RewardReactContext.Provider>
    );
}
