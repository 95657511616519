import { useEffect } from 'react';
import { selector, selectorFamily, useRecoilValue } from 'recoil';
import { config } from './config';
import { eosRPCQuery } from './hooks/useRPC';
import { BurnerABI } from './abi';
import { explorerApiQuery } from './hooks/useExplorerApi';
import { IAsset, ITemplate } from 'atomicassets/build/API/Explorer/Objects';
import LoadingIndicator from './LoadingIndicator';
import { Fragment, Suspense, useCallback, useMemo, useState } from 'react';
import ErrorBoundary from './ErrorBoundary';
import { authQueries, useAuth } from './hooks/useAuth';
import cx from 'classnames';
import { useRefreshTokenBalance } from './hooks/useTokenBalance';
import { toast } from 'react-toastify';
import { Dialog, Transition } from '@headlessui/react';
import IconX from './icons/x';
import { asset } from 'eos-common';

export default function Burner() {
    return (
        <div className="flex flex-col">
            <div className="mx-auto container max-w-7xl flex flex-col items-center space-y-4 overflow-hidden p-8">
                <ErrorBoundary>
                    <Suspense fallback={<LoadingIndicator />}>
                        <BurnableList />
                    </Suspense>
                </ErrorBoundary>
            </div>
        </div>
    );
}

function BurnableList() {
    const burnables = useRecoilValue(burnablesQuery);
    return (
        <div className="w-full flex flex-col space-y-4">
            {burnables.map((rb) => (
                <BurnableTemplateRow key={rb.entity.template_id} rb={rb} />
            ))}
        </div>
    );
}

function BurnableTemplateRow(props: { rb: RichBurnable }) {
    const { template, entity } = props.rb;
    const owned = useRecoilValue(ownedBurnablesForTemplate(entity.template_id));
    const [selectorVisible, setSelectorVisible] = useState(false);

    return (
        <div className="w-full bg-dark rounded text-white border-yellow flex flex-col md:flex-row space-y-4 md:space-x-2 md:space-y-0 md:justify-between px-2 py-4 md:px-8">
            <div className="flex flex-row space-x-2">
                <div className="w-24">
                    {template && (
                        <img
                            src={
                                'https://cloudflare-ipfs.com/ipfs/' +
                                template.immutable_data?.img
                            }
                            className="h-full w-full object-cover"
                            alt="NFT artwork"
                        />
                    )}
                </div>
                <div className="flex flex-col space-y-4">
                    <span>{template?.immutable_data['name']}</span>
                    <span>
                        You own {owned.length} asset
                        {owned.length !== 1 ? 's' : ''} of this template
                    </span>
                </div>
            </div>
            <div
                className={cx('flex items-center', {
                    'opacity-50 cursor-not-allowed': owned.length === 0,
                })}
            >
                <button
                    className="btn-neon-yellow"
                    disabled={owned.length === 0}
                    onClick={() => setSelectorVisible(true)}
                >
                    Burn 1 for {entity.price.quantity}
                </button>
            </div>
            <AssetSelector
                rb={props.rb}
                visible={selectorVisible}
                close={() => setSelectorVisible(false)}
            />
        </div>
    );
}

function AssetSelector(props: {
    rb: RichBurnable;
    visible: boolean;
    close: () => void;
}) {
    const auth = useAuth();
    const refreshTokenBalance = useRefreshTokenBalance();

    const owned = useRecoilValue(
        ownedBurnablesForTemplate(props.rb.entity.template_id),
    );

    const ownedSortedByMint = useMemo(
        () =>
            [...owned].sort((a, b) => {
                const mintA = parseInt(a.template_mint || '1', 10);
                const mintB = parseInt(b.template_mint || '1', 10);
                return mintB - mintA;
            }),
        [owned],
    );

    const [assetIDs, setAssetIDs] = useState(
        owned.length > 0 ? [owned[0].asset_id] : [],
    );

    useEffect(() => {
        if (assetIDs.length === 0 && owned.length > 0) {
            setAssetIDs([owned[0].asset_id]);
        }
    }, [owned, assetIDs, setAssetIDs]);

    const reward = asset(props.rb.entity.price.quantity).times(assetIDs.length);

    const close = props.close;

    const [working, setWorking] = useState(false);
    const sendToBurn = useCallback(async () => {
        if (assetIDs.length < 1) {
            return;
        }
        try {
            setWorking(true);
            await auth.transact((pm) => ({
                account: 'atomicassets',
                name: 'transfer',
                authorization: [pm],
                data: {
                    from: auth.accountName,
                    to: config.burning.contractAccount,
                    asset_ids: assetIDs,
                    memo: 'burn',
                },
            }));
            refreshTokenBalance();
            toast.info('Tokens have been deposited to your account.');
            close();
        } finally {
            setWorking(false);
        }
    }, [auth, refreshTokenBalance, setWorking, assetIDs, close]);

    return (
        <Transition appear show={props.visible} as={Fragment}>
            <Dialog
                as="div"
                className="fixed inset-0 z-10 overflow-y-auto"
                onClose={props.close}
            >
                <div className="min-h-screen px-4 text-center">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Dialog.Overlay className="fixed inset-0 bg-dark bg-opacity-50" />
                    </Transition.Child>
                    {/* This element is to trick the browser into centering the modal contents. */}
                    <span
                        className="inline-block h-screen align-middle"
                        aria-hidden="true"
                    >
                        &#8203;
                    </span>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 scale-95"
                        enterTo="opacity-100 scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 scale-100"
                        leaveTo="opacity-0 scale-95"
                    >
                        <div className="inline-block w-full max-w-md p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-dark border-neon-yellow">
                            <Dialog.Title
                                as="h3"
                                className="text-lg font-medium leading-6 text-gray-100"
                            >
                                Select asset
                            </Dialog.Title>
                            <div className="absolute top-0 right-0 p-6">
                                <button onClick={props.close}>
                                    <IconX className="text-white" />
                                </button>
                            </div>
                            <div className="mt-2 flex flex-col space-y-2">
                                {assetIDs.map((assetID, i) => (
                                    <select
                                        key={i}
                                        value={assetID}
                                        onChange={(e) =>
                                            setAssetIDs((current) => {
                                                const next = [...current];
                                                next[i] = e.target.value;
                                                if (next[i] === '--') {
                                                    next.splice(i, 1);
                                                }
                                                return next;
                                            })
                                        }
                                    >
                                        {assetIDs.length > 1 && (
                                            <option value="--">Remove</option>
                                        )}
                                        {ownedSortedByMint.map((asset) => (
                                            <option
                                                key={asset.asset_id}
                                                value={asset.asset_id}
                                            >
                                                #{asset.template_mint} (
                                                {asset.asset_id})
                                            </option>
                                        ))}
                                    </select>
                                ))}
                                <button
                                    className="text-yellow"
                                    onClick={() =>
                                        setAssetIDs((current) => [
                                            ...current,
                                            owned[0].asset_id,
                                        ])
                                    }
                                >
                                    Select more
                                </button>
                            </div>
                            <div className="mt-4">
                                <button
                                    type="button"
                                    className="inline-flex justify-center px-4 py-2 text-sm font-medium text-primary bg-gray-900 border border-transparent rounded-md hover:bg-gray-800 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-yellow-500"
                                    onClick={sendToBurn}
                                >
                                    {working ? (
                                        <LoadingIndicator />
                                    ) : (
                                        <>Burn for {reward.to_string()}</>
                                    )}
                                </button>
                            </div>
                        </div>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition>
    );
}

type RichBurnable = {
    entity: BurnerABI._burnable_entity;
    template: ITemplate | null;
};

const burnablesQuery = selector({
    key: 'burner/burnableTemplateIDsQuery',
    get: async ({ get }): Promise<Array<RichBurnable>> => {
        const rpc = get(eosRPCQuery);
        const ah = get(explorerApiQuery);

        const res = await rpc.get_table_rows({
            code: config.burning.contractAccount,
            scope: config.burning.contractAccount,
            table: 'burnable',
            limit: 100,
        });

        const rows = res.rows as Array<BurnerABI._burnable_entity>;

        const templates = await ah.getTemplates({
            ids: rows.map((r) => r.template_id).join(','),
        });

        return rows.map((entity) => ({
            entity,
            template:
                templates.find(
                    (t) => parseInt(t.template_id, 10) === entity.template_id,
                ) ?? null,
        }));
    },
});

const ownedBurnables = selector({
    key: 'burner/ownedBurnables',
    get: async ({ get }): Promise<Array<IAsset>> => {
        const name = get(authQueries.activeUserName);
        if (!name) {
            return [];
        }
        const burnableTemplateIDs = new Set(
            get(burnablesQuery).map((rb) => rb.entity.template_id),
        );
        const ah = get(explorerApiQuery);

        const assets: Array<IAsset> = [];

        let hasMore = true;
        let nextPage = 1;
        const limit = 100;

        let failSafe = 0;
        while (hasMore && failSafe < 2000) {
            failSafe++;

            const res = await ah.getAssets(
                {
                    // @ts-ignore
                    owner: name,
                    template_whitelist:
                        Array.from(burnableTemplateIDs).join(','),
                },
                nextPage,
                limit,
            );
            assets.push(...res);
            hasMore = res.length === limit;
            nextPage++;
        }

        return assets;
    },
});

const ownedBurnablesForTemplate = selectorFamily({
    key: 'burner/ownedBurnablesForTemplate',
    get:
        (tid: number) =>
        ({ get }): Array<IAsset> => {
            return get(ownedBurnables).filter(
                (asset) =>
                    parseInt(asset.template?.template_id ?? '-1', 10) === tid,
            );
        },
});
