import { Dialog, Transition } from '@headlessui/react';
import { Fragment, useState } from 'react';
import { frozenStatus, useIsFrozen } from './AssetScanner';
import IconX from './icons/x';

type Props = {
    isOpen: boolean;
    requestClose: () => void;
};

export default function FrozenCheckDialog(props: Props) {
    const { isOpen } = props;

    const [id, setID] = useState('');

    const isFrozen = useIsFrozen();

    const [frozenStatus, setFrozenStatus] = useState<frozenStatus | undefined>(
        undefined,
    );

    async function checkFrozenStatus() {
        setFrozenStatus(undefined);
        const frozen = await isFrozen(id);
        setFrozenStatus(frozen);
    }

    const requestClose = () => {
        props.requestClose();
        setFrozenStatus(undefined);
        setID('');
    };

    return (
        <Transition appear show={isOpen} as={Fragment}>
            <Dialog
                as="div"
                className="fixed inset-0 z-10 overflow-y-auto"
                onClose={requestClose}
            >
                <div className="min-h-screen px-4 text-center">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Dialog.Overlay className="fixed inset-0 bg-dark bg-opacity-50" />
                    </Transition.Child>

                    {/* This element is to trick the browser into centering the modal contents. */}
                    <span
                        className="inline-block h-screen align-middle"
                        aria-hidden="true"
                    >
                        &#8203;
                    </span>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 scale-95"
                        enterTo="opacity-100 scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 scale-100"
                        leaveTo="opacity-0 scale-95"
                    >
                        <div className="inline-block w-full max-w-md p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-dark border-neon-yellow">
                            <Dialog.Title
                                as="h3"
                                className="text-lg font-medium leading-6 text-gray-100"
                            >
                                ❄️ Check freeze status of an asset
                            </Dialog.Title>
                            <div className="absolute top-0 right-0 p-6">
                                <button onClick={requestClose}>
                                    <IconX className="text-white" />
                                </button>
                            </div>
                            <div className="mt-2">
                                <p className="text-sm text-gray-200">
                                    Enter an asset id
                                </p>
                                <input
                                    type="text"
                                    name="asset"
                                    placeholder="1099512048298"
                                    value={id}
                                    pattern="^\d*$"
                                    onChange={(e) => setID(e.target.value)}
                                />
                            </div>

                            <div className="text-white py-2">
                                {frozenStatus && frozenStatus.frozen && (
                                    <div>
                                        <span>
                                            Asset is frozen until{' '}
                                            {new Date(
                                                frozenStatus.unfreezesAt,
                                            ).toLocaleString()}
                                        </span>
                                    </div>
                                )}
                                {frozenStatus && !frozenStatus.frozen && (
                                    <div>
                                        <span>Asset is not frozen</span>
                                    </div>
                                )}
                            </div>

                            <div className="mt-4">
                                <button
                                    type="button"
                                    className="inline-flex justify-center px-4 py-2 text-sm font-medium text-primary bg-gray-900 border border-transparent rounded-md hover:bg-gray-800 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-yellow-500"
                                    onClick={checkFrozenStatus}
                                >
                                    Check
                                </button>
                            </div>
                        </div>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition>
    );
}
